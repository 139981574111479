import React, { useState, createContext, useContext, useRef } from "react";

import { formatNumber } from './../../../utilities/helpers';

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

import "./GAModalProvider.scss";

// Create the context
export const GAModalContext = createContext(undefined);

export const useGAModal = () => {
    const context = useContext(GAModalContext);
    if (!context) {
        throw new Error("Context must be used within a GAModalProvider");
    }
    return context;
};

// Create the provider component
export const GAModalProvider = ({ children, seatsInPackage, setSelectedSeatIds, isAssigningToFan, soldMap }) => {
    const dialogRef = useRef(null);
    const [section, setSection] = useState(null);
    const [availableSpots, setAvailableSpots] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const numberInputRef = useRef(null);

    const openModal = (section) => {
        setSection(section);
        // set available spots to all seats in ga section 
        // if assigning to fan, only set available spots to seats that are selected 
        if (isAssigningToFan) {
            let seatsToAssignFrom = seatsInPackage?.gaSeats[section?.sectionId];
            const soldSeatsInPackage = soldMap?.get(section?.sectionId);

            // If we found some sold seats; filter those out
            if (soldSeatsInPackage) {
                seatsToAssignFrom = seatsToAssignFrom.filter(seatId => 
                    !soldSeatsInPackage.includes(seatId)
                );
            }
            setAvailableSpots(seatsToAssignFrom);
        } else {
            // All seats in section
            setAvailableSpots(section?.spots);
        }
        setValidationError(false); // Reset validation error when opening modal
        if (numberInputRef.current) {
            numberInputRef.current.value = "0"; // Reset input value
        }
        dialogRef.current?.showModal();
    };

    const closeModal = () => {
        setValidationError(false); // Reset validation error when closing modal
        dialogRef.current?.close();
    };

    const selectGASeats = (sectionId, seats) => {
        setSelectedSeatIds((prevSelected) => {
            const newSelected = { ...prevSelected };
            if (!newSelected.gaSeats) {
                newSelected.gaSeats = {};
            }

            newSelected.gaSeats[sectionId] = seats;

            return newSelected;
        });
    };

    const selectXSpots = (numberSelected) => {
        const sliceOfSpots = section?.spots.slice(0, numberSelected);
        selectGASeats(section?.sectionId, sliceOfSpots);
        closeModal();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const numberOfSpots = parseInt(numberInputRef.current.value, 10);

        if (numberOfSpots > availableSpots.length) {
            setValidationError(true);
            return;
        }

        if (numberOfSpots > 0 && numberOfSpots <= availableSpots.length) {
            setValidationError(false);
            selectXSpots(numberOfSpots);
        }
    };

    const handleInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setValidationError(value > availableSpots.length);
    };

    return (
        <GAModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <dialog ref={dialogRef} className="vjx-admin-ga-modal">
                <Form onSubmit={handleSubmit}>
                    <h4 className="mb-3 fw-bold">Add tickets to selection</h4>

                    <p className="mb-4 text-dark">
                        Total available tickets in this section: {formatNumber(availableSpots?.length)}
                    </p>

                    <Form.Group className="mb-4">
                        <Form.Label>Number of tickets</Form.Label>
                        <Form.Control
                            ref={numberInputRef}
                            type="number"
                            defaultValue="0"
                            min="1"
                            max={availableSpots.length}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    {validationError && (
                        <Alert
                            variant="danger"
                            className="d-flex gap-2 mt-3 mb-4 align-items-center"
                        >
                            <div className="body">
                                <p>
                                    Number of tickets you've added exceeds the max limit of
                                    available tickets.
                                </p>
                            </div>
                        </Alert>
                    )}

                    <div className="d-flex justify-content-end gap-2">
                        <Button
                            variant="light"
                            type="button"
                            onClick={closeModal}
                            className="fw-medium"
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" className="fw-medium px-4">
                            Add tickets
                        </Button>
                    </div>
                </Form>
            </dialog>
        </GAModalContext.Provider>
    );
};
