import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';
import { FromPageProvider } from '../../providers/FromPageProvider';

import { getInvoices } from '../../utilities/api';

import Card from 'react-bootstrap/Card'

import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';
import { EmptyContainer } from '../EmptyContainer';
import { FansList } from './FansList';
import { FanProfileWrapper } from '../FanProfileWrapper';

export default function AssignPackagesWrapper({ uuid, fanId }) {

    const location = useLocation()

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { updateIsPackagePublished, updateIsPackageOnsale, updateIsPackageSoldout, isPackageSoldout, updateHasPackageEnded, hasAllEventsInPackageEnded } = useContext(PackageDetailsContext)

    const [hasPermission, setHasPermission] = useState(true);
    const [invoices, setInvoices] = useState({})

    useEffect(() => {
        showLoading()
        loadInvoices()
        console.log('asssign mount', isLoading);

        return () => console.log('assign unmount', isLoading);
    }, [])

    const loadInvoices = () => {
        getInvoices(uuid, '', 'all')
            .then((res) => {
                setInvoices(res.data)
                // update context
                updateIsPackageOnsale()
                updateHasPackageEnded()
                updateIsPackageSoldout()
                updateIsPackagePublished()
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <div className='position-relative'>
                        <section
                            className={`max-width-wrapper ${!hasPermission ? "overlay" : ""
                                }`}
                        >
                            {(!location.pathname.includes('/profile')) ? (
                                <section>
                                    <header className='section-header section-heading--flex section-heading section-heading--secondary'>
                                        <div className="section-heading">
                                            <h1>Assign Package</h1>
                                            <p className='section-header-desc'>Manually add your fan information for package tickets</p>
                                        </div>
                                        <Link className={`btn btn-outline-light btn-lg btn-plus ${isPackageSoldout || hasAllEventsInPackageEnded ? 'btn-link-disabled' : ''}`} to={`/mypackage/${uuid}/seatmap/assign`}>Assign package</Link>
                                    </header>
                                    <section>
                                        {invoices?.length > 0 ? (
                                            <FansList packageId={uuid} invoices={invoices} hasAllEventsInPackageEnded={hasAllEventsInPackageEnded} isPackageSoldout={isPackageSoldout} />
                                        ) : (
                                            <Card body>
                                                <EmptyContainer style='center lg'>
                                                    <p>
                                                        No list created
                                                    </p>
                                                    <p className='subtitle'>
                                                        Click on Assign package above to add your first collection
                                                    </p>
                                                </EmptyContainer>
                                            </Card>
                                        )}
                                    </section>
                                </section>
                            ) : (
                                <FromPageProvider>
                                    <FanProfileWrapper id={fanId} />
                                </FromPageProvider>
                            )}
                        </section>

                        {!hasPermission && <NoPermissionsContainer />}
                    </div>
                </>
            )}
        </>
    )
}