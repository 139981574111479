import React from 'react';

import { formatNumber, formatCurrency, getUrl } from '../../../../utilities/helpers';

import { CopyButton } from '../../../CopyButton';
import { EditDeleteDropdown } from '../../../EditDeleteDropdown';

export default function TrackingLink({ promo, handleShow, hasPermission, isGAOnlyEvent, hasEventEnded }) {

    return (
        <div className='flex-row' role="rowgroup">
            <div className='list-table-col text-truncate' role="cell">
                <span>{promo.name}</span>
            </div>
            <div className="list-table-col xl" role="cell">
                <div className="d-flex align-items-center">
                    <div className="text-truncate">
                        <span>{`${getUrl(promo.url, isGAOnlyEvent, `?code=${promo.code}`)}`}</span>
                    </div>
                    <CopyButton link={promo.url} code={promo.code} isGAOnlyEvent={isGAOnlyEvent} isDisabled={hasEventEnded} />
                </div>
            </div>
            <div className="list-table-col" role="cell">
                <span>{formatNumber(promo.views)}</span>
            </div>
            <div className="list-table-col" role="cell">
                <span>{formatNumber(promo.ticketsSold)}</span>
            </div>
            <div className="list-table-col" role="cell">
                <span>{formatCurrency(promo.grossSales)}</span>
            </div>
            {!promo.default && (
                <span className="btn-more-col" role="cell">
                    {/* <EditDeleteDropdown canEdit={false} handleShow={handleShow} hasPermission={hasPermission} /> */}
                </span>
            )}
        </div>
    );
}
