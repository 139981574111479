import React, { useState, useEffect } from 'react';
import { LinkContainer } from "react-router-bootstrap";

import { capitalizeString, formatCurrency, formatDateTime, formatNumber, getTicket, getTimezoneDate } from '../../utilities/helpers';

import Dropdown from 'react-bootstrap/Dropdown';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Table from 'react-bootstrap/Table';

import { MoreIcon } from '../MoreIcon';
import { TicketRow } from './TicketRow'

import './orderSummary.scss';

// component used on orders page and refund orders page
export default function OrderSummary({ timezone, order, showDropdown = true, isOpen = false, hasPermission, submitRefund }) {
    const { type, intentDetails } = order;

    const [open, setOpen] = useState(isOpen);

    const [ticketCount, setTicketCount] = useState(0)

    useEffect(() => {
        setTicketCount(order?.tickets?.length)
    }, [order])

    useEffect(() => {

    }, [ticketCount])

    const getOrderType = (order) => {
        let type = order.status !== "completeFromTransfer" ? order?.type === 'resale' ? 'resale' : 'primary' : 'transfer';
        return type
    }

    const getOrderTypeColor = (order) => {
        const type = getOrderType(order)

        if (type === 'transfer') return 'success'

        if (type === 'resale') return 'secondary'

        else return type
    }

    const getLabel = (type) => {
        return type == 'transfer' ? 'Transferred To' : 'Purchased By'
    }

    const refundOrder = (order) => {
        submitRefund(order?.uuid, "Show was cancelled")
    }

    return (
        <li>
            <Card body className='order-summary-card'>
                <Button variant="default" className="btn-toggle p-0 pb-2"
                    onClick={() => setOpen(!open)}
                    aria-controls="order-summary-text"
                    aria-expanded={open}
                >

                    <Stack className="align-items-start">
                        <h1 className='card-body-title'>{formatCurrency(order?.total)}</h1>
                        <Stack direction="horizontal" gap={3} className='card-body-subtitle--flex'>
                            <p>Order {order?.orderId}</p>
                            <Badge bg="default" className={`badge-outline badge-outline--${getOrderTypeColor(order)}`}>{getOrderType(order)}</Badge>
                        </Stack>
                        {/* {showDropdown && (
                        <Dropdown className="btn-more" drop="start">
                        <Dropdown.Toggle variant="default">
                        <MoreIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Stack as="ul" gap={2}>
                        {!refund && (
                            <li>
                            <LinkContainer to={{
                                pathname: 'refund',
                                search: `?order=${order?.uuid}`
                            }}>
                            <Dropdown.Item className={`btn-refund ${!hasPermission && 'btn-link-disabled'} `}>
                            Refund tickets
                            </Dropdown.Item>
                            </LinkContainer>
                            </li>
                            )}
                            <li>
                            <LinkContainer to={'/attendees'}>
                            <Dropdown.Item className="btn-view">
                            View attendees list
                            </Dropdown.Item>
                            </LinkContainer>
                            </li>
                            </Stack>
                            </Dropdown.Menu>
                            </Dropdown>
                        )} */}
                    </Stack>
                </Button>
                <Collapse in={open}>
                    <div id="order-summary-text">
                        <Stack gap={1} key={order.orderId} className="transaction">
                            <span className='caption'>{getLabel(type)}</span>
                            <div className="transaction-desc">
                                <p>{capitalizeString(`${order?.users_permissions_user?.firstName} ${order?.users_permissions_user?.lastName}`)}</p>
                                <p>{formatDateTime(getTimezoneDate(order?.processedAt, timezone))}</p>
                                <span className='caption fw-normal'>{formatNumber(ticketCount)} ticket{ticketCount > 1 ? "s" : ""}</span>
                                <p>Total {formatCurrency(order?.total)}
                                    {order?.total !== 0 && (
                                        <span> paid by {order?.intentDetails?.charges?.data[0]?.payment_method_details?.card?.brand} {order?.intentDetails?.charges?.data[0]?.payment_method_details?.card?.last4}</span>
                                    )}
                                </p>
                            </div>
                            {order?.status === 'refunded' && (
                                <Stack gap={1} className='mt-2'>
                                    <p>Refunded on {formatDateTime(getTimezoneDate(intentDetails?.canceled_at, timezone))}</p>
                                    <p>{formatCurrency(order?.total)} refunded to original payment method</p>
                                </Stack>
                            )}
                        </Stack>
                        {order && order?.status !== "refunded" &&
                            <Button
                                variant="outline-light"
                                size="sm"
                                className="btn-refund mt-2"
                                onClick={() => refundOrder(order)}
                                aria-controls='order'
                            >Refund Order</Button>
                        }

                        <Table className='mt-4'>
                            <thead>
                                <tr>
                                    <th>Ticket Buyer</th>
                                    <th>Quantity</th>
                                    <th>Transaction type</th>
                                    <th>Offer type</th>
                                    <th>Paid</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[...Array(ticketCount)].map((x, i) => {
                                    return <TicketRow key={i} ticket={getTicket(order)[i]} ticketBuyer={`${order?.users_permissions_user?.firstName} ${order?.users_permissions_user?.lastName}`} ticketCount={ticketCount} total={order?.total} />
                                })}
                                <tr className='total-row'>
                                    <td colSpan={4}>Total</td>
                                    <td className='text-end'>{formatCurrency(order?.total)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Collapse>
            </Card>
        </li>
    );
}