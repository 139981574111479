import axios from "axios";

import authService from './services/auth.service';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 1200000,
    withCredentials: false
})

// Add a request interceptor
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    const isLoggedIn = (authService?.isLoggedIn() || authService?.getSignUpToken());
    if (isLoggedIn) {
        if (authService?.isLoggedIn()) config.headers['Authorization'] = `Bearer ${authService.token()}`;
        if (authService?.getSignUpToken()) {
            config.headers['Authorization'] = `Bearer ${authService.getSignUpToken()}`;
        }
    }
    return config;
}, function (error) {
    // Do something with request errors
    console.error('API Error:', error.response.data)
    return Promise.reject(error);
});

export const register = async (data) => {
    return axios.post(`${process.env.REACT_APP_AUTH}/api/auth/local/register`, data)
}

export const signUp = async (data) => {
    return instance.post('/verifies/admin-signup', data)
}

export const login = async (data) => {
    return axios.post(`${process.env.REACT_APP_AUTH}/api/auth/local`, data)
}

export const getMyOrganizations = async () => {
    return instance.get('/organizations/myOrgs')
}

export const getOrgVenues = async () => {
    return instance.get('/organizations/venues')
}

export const getMyPermissions = async () => {
    return instance.get('/organizations/my-permissions')
}

export const getMe = async (token) => {
    instance.interceptors.request.use(function (config) {
        config.headers['Authorization'] = `Bearer ${token}`;
        return config
    })

    return instance.get('/users/me?populate[organizations][populate][0]=members&populate[role][fields][0]=name&populate[organization_role][populate][organization_permissions][fields][0]=name&populate[organization_role][populate][organization_permissions][fields][1]=key')
}

export const getTeam = async () => {
    return instance.get('/organizations/team')
}

export const getTeamMembers = async () => {
    return instance.get('/organizations/get-team-members')
}

export const getAutomaticReports = async (uuid) => {
    return instance.get(`/organizations/get-automatic-reports?eventUUID=${uuid}`)
}

export const addMember = async (data) => {
    return instance.post('/organizations/add-member', data)
}

export const createDiscount = async (data) => {
    return instance.post('/offer-discount/create-discount', data)
}

export const getEventDiscount = async (uuid) => {
    return instance.get(`/offer-discount/get-event-discounts?eventUUID=${uuid}`)
}

export const createEventReport = async (data) => {
    return instance.post('/organizations/create-event-report', data)
}

export const updateOrgDetails = async (data) => {
    return instance.post('/organizations/update-details', data)
}

// email is not taken by another user 
export const emailValid = async (data) => {
    return instance.post('/organizations/email-valid', data)
}

// email is a known email address 
export const validEmail = (data) => {
    return instance.post('/verifies/emailValid', data)
}

export const updateUserEmail = async (data) => {
    return instance.post('/organizations/update-user-email', data)
}

export const updateUsersName = async (data) => {
    return instance.post('/organizations/update-users-name', data)
}

export const resetPassword = async (data) => {
    return instance.post('/organizations/update-users-password', data)
}

export const createOrganization = async (data) => {
    return instance.post('/verifies/admin-create-org', data)
}

export const getOrganizationRoles = async () => {
    return instance.get('/organization-roles/roles')
}

export const getOrganizationPermissions = async () => {
    return instance.get('/organization-permissions')
}

export const createOrEditRole = async (data) => {
    return instance.post('/organization-roles/create', data)
}

export const createPaymentInfo = async (data) => {
    return instance.post('/organizations/create-payment-info', data)
}

export const getPaymentInfo = async () => {
    return instance.get(`/organizations/payment-info`)
}

export const createW9 = async (data) => {
    return instance.post('/organizations/create-w9', data)
}

export const createPromoLink = async (data) => {
    return instance.post('/organizations/create-promo', data)
}

export const editPromoLink = async (data) => {
    return instance.post('/organizations/edit-promo', data)
}

export const createOrEditMember = async (data) => {
    return instance.post('/organizations/invite-member', data)
}

export const removeTeamMember = async (data) => {
    return instance.post('/organizations/remove-member', data)
}

export const removeRoles = async (data) => {
    return instance.post('/organization-roles/remove', data)
}

export const removeBankAccount = async (data) => {
    return instance.post('/organizations/remove-bankaccount', data)
}

export const getCategories = async () => {
    return instance.get('/categories')
}

export const getVenues = async () => {
    return instance.get('/venues')
}

export const getPromoStats = async (uuid) => {
    return instance.get(`/organizations/promo-stats?uuid=${uuid}`)
}

export const getEventTicketTypes = async (uuid) => {
    return instance.get(`/organizations/event-ticket-types?uuid=${uuid}`)
}

export const attendeesList = async (uuid) => {
    return instance.get(`/attendees-list/event?uuid=${uuid}`)
}

export const getW9 = async (uuid) => {
    return instance.get(`/organizations/w9`)
}

export const guestList = async (uuid) => {
    return instance.get(`/organizations/guest-list?uuid=${uuid}`)
}

export const createEvent = async (data) => {
    return instance.post('/events', data)
}

export const createGuestList = async (data) => {
    return instance.post('/organizations/create-guest-list', data)
}

export const removeGuestList = async (data) => {
    return instance.post('/organizations/remove-guest-list', data)
}

export const editEvent = async (data) => {
    return instance.put('/events/update-event', data)
}

export const addDetailsToEvent = async (data) => {
    return instance.post('/organizations/add-details', data)
}

export const getEvents = async () => {
    return instance.get('/organizations/events')
}

export const getEvent = async (uuid) => {
    return instance.get(`/organizations/event?uuid=${uuid}`)
}

export const getEventStats = async (uuid, range = '') => {
    return instance.get(`/organizations/event-stats?uuid=${uuid}&range=${range}`)
}

export const getAllEventStats = async (status, query, start) => {
    return instance.get(`/organizations/all-event-stats?status=${status}&query=${query}&start=${start}`)
}

export const getOrders = async (uuid, query, ordersView) => {
    return instance.get(`/organizations/event-orders?uuid=${uuid}&query=${query}&ordersView=${ordersView}`)
}

export const getAttendees = async (uuid) => {
    return instance.get(`/organizations/event-attendees?eventUUID=${uuid}`)
}

export const getTaxRates = async (city, state) => {
    return instance.get(`/organizations/tax-rates?city=${city}&state=${state}`)
}

export const publishEvent = async (data) => {
    return instance.post('/events/publish', data)
}

export const updateEventStatus = async (data) => {
    return instance.put('/events/update-event-status', data)
}

export const updateEventVisibility = async (data) => {
    return instance.put('/events/update-event-visibility', data)
}

export const createTickets = async (data) => {
    return instance.post('/tickets', data)
}

export const updateTickets = async (data) => {
    return instance.post('/tickets/update-all', data)
}

export const inactivateTickets = async (data) => {
    return instance.post('/tickets/inactivate', data)
}

export const upload = async (data) => {
    return instance.post('/upload/', data)
}

export const getTicketFees = async (data) => {
    return instance.post('/organizations/get-ticket-fees', data)
}

export const getTicketDetails = async (uuid) => {
    return instance.get(`/organizations/all-ticket-details?uuid=${uuid}`)
}

export const getCampaigns = async (uuid) => {
    return instance.get(`/organizations/campaigns?uuid=${uuid}`)
}

export const removeCampaign = async (id) => {
    return instance.delete(`/organizations/remove-campaign/${id}`)
}

export const removeAutomaticReport = async (id) => {
    return instance.delete(`/organizations/remove-automtic-report/${id}`)
}

export const removeOffer = async (id) => {
    return instance.delete(`/organizations/remove-offer/${id}`)
}

export const inviteValid = async (code) => {
    return instance.get(`/organizations/invite-valid?code=${code}`)
}

export const ticketsByGroup = async (groupCode, eventUUID) => {
    return instance.get(`/organizations/tickets-by-group?ticketGroup=${groupCode}&eventUUID=${eventUUID}`)
}

export const getAttendeesList = async (uuid) => {
    return instance.get(`/attendees-list/event?uuid=${uuid}`)
}

export const checkIn = async (data) => {
    return instance.post(`/attendees-list/checkInGuest`, data)
}

export const searchAttendees = async (uuid, query) => {
    return instance.get(`/attendees-list/search?uuid=${uuid}&query=${query}`)
}

export const getSales = async (uuid, query) => {
    return instance.get(`/organizations/sales?uuid=${uuid}&query=${query}`)
}

export const messageAttendees = async (data) => {
    return instance.post(`/organizations/contact-attendees`, data)
}

export const eventsForReport = async (eventStatus, query) => {
    return instance.get(`/organizations/report-events?status=${eventStatus}&query=${query}`)
}

export const eventOrdersForReport = async (uuids, types, startDate, endDate) => {
    return instance.get(`/organizations/report-event-orders?uuids=${uuids}&orderTypes=${types}&start=${startDate}&end=${endDate}`)
}

export const refundOrders = async (data) => {
    return instance.post(`/organizations/refund-orders`, data)
}

export const createAttractions = async (data) => {
    return instance.post(`/attractions`, data)
}

export const updateAttraction = async (data) => {
    return instance.post('/organizations/update-attraction', data)
}

export const removeAttraction = async (id) => {
    return instance.delete(`/organizations/remove-attraction/${id}`)
}

export const createorEditTemplate = async (data) => {
    return instance.post(`/organizations/assign-seatmap`, data)
}

export const createOrEditOffers = async (data) => {
    return instance.post(`/organizations/assign-offer`, data)
}

export const scalingInventoryToEvent = async (data) => {
    return instance.post(`/organizations/scaling-inventory-to-event`, data)
}

export const createTicketGroups = async (data) => {
    return instance.post('/organizations/create-ticket-groups', data)
}

export const assignSeatmapToEvent = async (data) => {
    return instance.post(`/organizations/set-seatmap`, data)
}

export const resetMapState = async (data) => {
    return instance.post(`/organizations/reset-map-state`, data)
}

export const assignAvailabilityPeriod = async (data) => {
    return instance.post(`/organizations/set-availablility`, data)
}

export const getVenueEvents = async (uuid, id, orgUUID) => {
    return instance.get(`/packages/get-venue-events?roomUUID=${uuid}&seatmapId=${id}&orgUUID=${orgUUID}`)
}

export const createPackage = async (data) => {
    return instance.post('/packages/create', data)
}

export const removePackage = async (id) => {
    return instance.delete(`/packages/remove-package/${id}`)
}

export const getPackages = async (uuid) => {
    return instance.get(`/packages/get-packages?orgUUID=${uuid}`)
}

export const getPackage = async (uuid) => {
    return instance.get(`/packages/get-package?uuid=${uuid}`)
}

export const getPackageStats = async (uuid) => {
    return instance.get(`/packages/package-stats?uuid=${uuid}`)
}

export const updatePackageDetails = async (data) => {
    return instance.put(`/packages/update-details`, data)
}

export const updateSeatsInPackage = async (data) => {
    return instance.put(`/packages/update-seats-in-package`, data)
}

export const createInvoice = async (data) => {
    return instance.post('/packages/create-invoice', data)
}

export const getInvoices = async (uuid, query, status) => {
    return instance.get(`/get-invoices?uuid=${uuid}&query=${query}&status=${status}`)
}

export const getPackageHolders = async (uuid, query) => {
    return instance.get(`/get-package-holders?uuid=${uuid}&query=${query}`)
}

export const getFanProfile = async (uuid) => {
    return instance.get(`/packages/fan-details?uuid=${uuid}`)
}

export const publishPackage = async (data) => {
    return instance.post('/packages/publish', data)
}

export const updatePackageStatus = async (data) => {
    return instance.put('/packages/update-package-status', data)
}

export const updatePackageVisibility = async (data) => {
    return instance.put('/packages/update-package-visibility', data)
}