import { useState, useLayoutEffect, useEffect, useContext, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

import authService from './utilities/services/auth.service';
import UserContext from './context/User/User';
import LoadingContext from './context/Loading/Loading'
import { CreatePackageProvider } from './providers/CreatePackageProvider/CreatePackageProvider';

import { getOrganizationPermissions } from './utilities/api';
import { toggleContainer, changeBackground } from './utilities/helpers';

import { Navigation } from './components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Router from './Router';
import { jwtDecode } from "jwt-decode";

function App() {

    let location = useLocation();

    const user = authService.getUser()
    const me = useContext(UserContext)
    const token = authService.token()
    const decoded = token ? jwtDecode(token) : ''
    const navigate = useNavigate();

    const [
        authenticated,
        setAuthenticated
    ] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    const [orgPermissions, setOrgPermissions] = useState([])

    const [
        org,
        setOrganization
    ] = useState('');

    const [show, setShow] = useState(false);

    const handleClose = () => { authService.logoutUser(); setShow(false); navigate('/login') };

    useLayoutEffect(() => {
        changeBackground(location.pathname)
        toggleContainer(location.pathname);

        // remove session variables when clicking on new event or creating a new event 
        if (location.pathname == '/' || location.pathname == '/create') {
            ['mapId', 'isTemplate', 'priceLevels', 'holds', 'kills'].forEach(key => sessionStorage.removeItem(key))
        }
    }, [location.pathname])

    useEffect(() => {
        if (!user) return

        if (decoded) {
            if (Date.now() >= (decoded.exp * 1000)) {
                setShow(true)
            }
            return
        }
        let permissions = [];
        // Need to refactor to not hit db on every route change
        getOrganizationPermissions().then((res) => {
            Object.values(res.data.data).map(obj => permissions.push({ id: obj?.id, name: obj?.attributes?.name }))
            setOrgPermissions(permissions);
        })
    }, [location, show])

    // useCallback ensures showLoading and hideLoading aren't recreated on every render
    const showLoading = useCallback(() => setIsLoading(true), []);
    const hideLoading = useCallback(() => setIsLoading(false), []);

    // useMemo prevents value from being recreated unless isLoading, showLoading, or hideLoading change
    // Memoized value to avoid unnecessary re-renders
    const value = useMemo(() => ({ isLoading, showLoading, hideLoading }), [isLoading, showLoading, hideLoading]);

    return (
        <>
            <UserContext.Provider value={{ authenticated, setAuthenticated, user, setOrganization, orgPermissions }}>
                <Navigation user={user} me={me} />
                <div className="container" id="main-container">
                    <LoadingContext.Provider value={value}>
                        <CreatePackageProvider>
                            <Router />
                        </CreatePackageProvider>
                    </LoadingContext.Provider>
                </div>
            </UserContext.Provider>

            <Modal show={show} onHide={handleClose} backdrop={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Expired Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your session has expired.  You will need to login to contine</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default App;
