import React, { useEffect, useState } from 'react'

import { assignSeatmapToEvent } from '../../../utilities/api';

import Card from 'react-bootstrap/Card'

import { ChooseMap } from './ChooseMap'
import { SelectMapModal } from '../SelectMapModal'

export default function SelectMap({ venueSlug, eventId, setStep, setIsTemplate, templates, seatmaps, isLoading, reloadData }) {

    const [show, setShow] = useState(false);

    const [choice, setChoice] = useState(null);

    // local state of selectedMapId in case of reverting back to original value
    const [selectedId, setSelectedId] = useState(null)

    const [isSaving, setIsSaving] = useState(false)

    const handleClick = (_, choice) => {
        setChoice(choice)
        setIsTemplate(choice === 2)
        sessionStorage.setItem('isTemplate', choice === 2)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setChoice(null)
        setSelectedId(null)
    }

    const handleCancel = () => handleClose()

    // select new map or template 
    const handleSubmit = () => {
        setIsSaving(true)
        // add configuration
        let data = {
            eventUUID: eventId,
            seatMapID: Number(selectedId)
        }
        // add selected seatmap to event 
        assignSeatmapToEvent(data)
            .then(() => {
                setIsSaving(false)
                handleClose()
                setStep(2)
                reloadData()
            }).catch((err) => {
                console.error(err)
                setIsSaving(false)
            })
    }

    return (
        <>
            <section>
                <header className="section-header section-heading section-heading--secondary">
                    <h1>Seat Map</h1>
                </header>
                <Card body className='card--light'>
                    <ChooseMap handleClick={handleClick} />
                </Card>
            </section>

            <SelectMapModal show={show} venueSlug={venueSlug} choice={choice} isLoading={isLoading} maps={seatmaps} templates={templates} selected={selectedId} setSelected={setSelectedId} isSaving={isSaving} handleClose={handleClose} handleCancel={handleCancel} handleSubmit={handleSubmit} />
        </>
    )
}