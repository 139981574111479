import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';
import { BannerProvider } from '../../providers/BannerProvider/BannerProvider';

import { getPackage } from '../../utilities/api';

import { getNowInTimezone, getTimezoneDate } from '../../utilities/helpers';

import { PageLoadingContainer, Sidenav, PackageBanner } from '../../components';

export default function MyPackagePage() {
    const { uuid } = useParams()

    const [windowSize, setWindowSize] = useState(undefined);

    const [eventPackage, setEventPackage] = useState()

    const [eventPackageContext, setEventPackageContext] = useState()

    const [packageVisibility, setPackageVisibility] = useState(null)

    const [packageStart, setPackageStart] = useState(null);

    const [packageEnd, setPackageEnd] = useState(null);

    const [canPublish, setCanPublish] = useState(false)

    const [isPackagePublished, setIsPackagePublished] = useState(false)

    const [isPackageOnsale, setIsPackageOnsale] = useState(false)

    const [isPackageSoldout, setIsPackageSoldout] = useState(false)

    const [hasPackageEnded, setHasPackageEnded] = useState(false)

    const [hasAllEventsInPackageEnded, setHasAllEventsInPackageEnded] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    const [selectedFanSeats, setSelectedFanSeats] = useState()

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            const el = document.querySelector("html")
            // Set window width/height to state
            setWindowSize(el.clientWidth);
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    })

    useLayoutEffect(() => {
        const el = document.querySelector("#banner")
        if (el) el.style.width = `${windowSize}px`
    }, [windowSize])

    useEffect(() => {
        setIsLoading(true)
        getPackage(uuid)
            .then((res) => {
                if (!res?.data) return
                setEventPackage(res?.data)
                setIsLoading(false);
            }).catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }, [uuid])

    useEffect(() => {
        // set state for package details context
        updatePackage(eventPackage)
        setPackageStart(eventPackage?.start)
        setPackageEnd(eventPackage?.end)
        const visibility = eventPackage?.visibility;
        setPackageVisibility(visibility ? visibility : null)
        updateCanPublish(eventPackage?.name, eventPackage?.seats)
        updateIsPackageSoldout(eventPackage)
        updateHasPackageEnded(eventPackage)
        updateIsPackagePublished(eventPackage)
        updateIsPackageOnsale(eventPackage)
        updateHasAllEventsInPackageEnded(eventPackage?.events, eventPackage?.timezone)
    }, [eventPackage])

    const updatePackage = (eventPackage) => {
        setEventPackageContext(eventPackage)
    }

    // can publish when package is published or package is onsale or event details are filled out and seats are assigned to package 
    const updateCanPublish = (name = eventPackageContext?.name, seats = eventPackageContext?.seats) => {
        const hasSeats = seats ? Object.keys(seats)?.length > 0 : false
        setCanPublish(((eventPackageContext?.status === 'published' || eventPackageContext?.status === 'on_sale') || (Boolean(name) && hasSeats)))
    }

    // switching automatically from published to onsale
    // package is onsale when package is onsale or start date is same or before now and end time is after now
    const updateIsPackageOnsale = (eventPackage = eventPackageContext) => {
        // update only if package if onsale or published or ended
        if (eventPackage && eventPackage?.status === 'published' || eventPackage?.status === 'on_sale' || eventPackage?.status === 'complete') {
            let isOnsale;

            const now = getNowInTimezone(eventPackage.timezone);
            const startTime = getTimezoneDate(eventPackage.start, eventPackage.timezone);
            const endTime = getTimezoneDate(eventPackage.end, eventPackage.timezone);
            // false -> package is sold out 
            // false -> package has ended
            // true -> package is onsale 
            // true -> start date is same or before now and end time is after now 
            isOnsale = (!eventPackage?.soldout && eventPackage?.status !== 'complete') ? (eventPackage?.status === 'on_sale' || (startTime.isSameOrBefore(now) && endTime.isAfter(now))) : false;
            setIsPackageOnsale(isOnsale)
            // if package is onsale or not onsale, it is published to prevent from publishing again
            setIsPackagePublished(true)
        }
    }

    // switching automatically from scheduled or unpublished to published
    // package is published when package is published or visibility date is same or before now 
    const updateIsPackagePublished = (eventPackage = eventPackageContext) => {
        // update only if canPublish and package is not onsale - can be onsale at same time it is published, don't want to change status to published 
        if (eventPackage && canPublish && eventPackage?.status !== 'on_sale' && eventPackage?.visibility) {
            // false -> package has ended
            // true -> package is published
            // true -> visibility date is same or before now 
            setIsPackagePublished(eventPackage?.status !== 'complete' ? (eventPackage?.status === 'published' || getTimezoneDate(eventPackage?.visibility, eventPackage?.timezone).isSameOrBefore(getNowInTimezone(eventPackage?.timezone))) : false);
        }
    }

    // switching automatically from onsale to soldout 
    // package is soldout when package is soldout or has not ended
    const updateIsPackageSoldout = (eventPackage = eventPackageContext) => {
        // update only if package is onsale or ended
        if (eventPackage && ((eventPackage?.status === 'on_sale' || eventPackage?.status === 'complete'))) {
            // true -> package is soldout 
            // false -> package has ended 
            setIsPackageSoldout(eventPackage?.status !== 'complete' && eventPackage?.soldout)
        }
    }

    // switching automatically from onsale to complete
    // package has ended when package has ended or end is same or before now  
    const updateHasPackageEnded = (eventPackage = eventPackageContext) => {
        // update only is package is onsale or ended
        if (eventPackage && (eventPackage?.status === 'on_sale' || eventPackage?.status === 'complete')) {
            const now = getNowInTimezone(eventPackage.timezone);
            const endTime = getTimezoneDate(eventPackage.end, eventPackage.timezone);

            // true -> package has ended 
            // true -> end time is same or before now  
            setHasPackageEnded(eventPackage?.status === 'complete' || endTime.isSameOrBefore(now));
        }
    }

    // has all events in package ended
    const updateHasAllEventsInPackageEnded = (events, timezone) => {
        // false -> package has no events 
        // false -> some events end time is after now (has not ended) 
        // true -> all event end time is same or before now  
        return setHasAllEventsInPackageEnded(events ? (events?.every(event => getTimezoneDate(event?.end, timezone).isSameOrBefore(getNowInTimezone(timezone)))) : false)
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer style="without-sidebar" />
            ) : (
                <>
                    <PackageDetailsContext.Provider value={{
                        eventPackage: eventPackageContext,
                        updatePackage,
                        packageStart,
                        storePackageStart: setPackageStart,
                        packageEnd,
                        storePackageEnd: setPackageEnd,
                        packageVisibility,
                        storePackageVisibility: setPackageVisibility,
                        canPublish,
                        updateCanPublish,
                        isPackagePublished,
                        updateIsPackagePublished,
                        isPackageOnsale,
                        updateIsPackageOnsale,
                        isPackageSoldout,
                        updateIsPackageSoldout,
                        hasPackageEnded,
                        updateHasPackageEnded,
                        updateHasAllEventsInPackageEnded,
                        hasAllEventsInPackageEnded,
                        selectedFanSeats,
                        setSelectedFanSeats
                    }}>
                        <BannerProvider>
                            <PackageBanner />
                        </BannerProvider>
                        <Sidenav />
                        <div className='spacer-md spacer-md--with-banner' id="main-content">
                            <Outlet />
                        </div>
                    </PackageDetailsContext.Provider>

                </>
            )}
        </>
    )
}