import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { isValidPhoneNumber } from "react-phone-number-input";

import PackageDetailsContext from "../../../context/PackageDetails/PackageDetails";

import { createInvoice } from "../../../utilities/api";

import {
  emailPatternMatch,
  formatCurrency,
  getFormattedTimezoneDate,
} from "../../../utilities/helpers";

import { AssignToFanModal } from "./AssignToFanModal";
import { ConfirmationModal } from "./ConfirmationModal";
import { ErrorModal } from "./ErrorModal";

// Create the context
const AssignToFanContext = createContext(undefined);

export const useAssignToFanModal = () => {
  const context = useContext(AssignToFanContext);
  if (!context) {
    throw new Error("Context must be used within a useAssignToFanModal");
  }
  return context;
};

// Provide certain type of modals used in the page
export const AssignToFanProvider = ({ children }) => {
  const navigate = useNavigate();

  const { eventPackage, selectedFanSeats } = useContext(PackageDetailsContext);

  // assign to fan modal
  const [show, setShow] = useState(false);

  // assign seats error modal
  const [showError, setShowError] = useState(false);

  // confirm seats were assigned to fan modal
  const [showConfirm, setShowConfirm] = useState(false);

  const [assignTo, setAssignTo] = useState();

  const [phoneNumber, setPhoneNumber] = useState("");

  const [isValidNumber, setIsValidNumber] = useState(true);

  const [isValidEmail, setIsValidEmail] = useState(true);

  // one-time or multiple
  const [paymentType, setPaymentType] = useState("one-time");

  // online or in-person
  const [paymentMethod, setPaymentMethod] = useState("online");

  // cash or check
  const [paymentOption, setPaymentOption] = useState("cash");

  const [paymentPlans, setPaymentPlans] = useState({});

  const [initialState, setInitialState] = useState();

  const [isValid, setIsValid] = useState(false);

  const [isValidPlans, setIsValidPlans] = useState(true);

  const [isSaving, setIsSaving] = useState(false);

  const [alert, setAlert] = useState({
    show: false,
    variant: "",
    message: "",
  });

  const [email, setEmail] = useState();

  const [sectionsToAssign, setSectionsToAssign] = useState(null);

  const [groupedSeatsToAssign, setGroupedSeatsToAssign] = useState(null);

  const mapping = eventPackage?.seatmap?.mapping;

  useEffect(() => {
    setInitialState({
      assignTo,
      paymentType: "one-time",
      paymentOption: "cash",
      paymentMethod: "online",
      paymentPlans,
    });
  }, []);

  useEffect(() => {
    const validAssignTo =
      assignTo?.firstName &&
      assignTo?.lastName &&
      assignTo?.email &&
      assignTo?.phoneNumber;

    const oneTime = paymentType === "one-time";

    const validPaymentOption = oneTime
      ? paymentOption
      : Object.keys(paymentPlans)?.length > 0 &&
        Object.values(paymentPlans)?.every(
          (plan) => plan?.date && plan?.amount
        );

    setIsValid(
      validAssignTo && validPaymentOption && isValidNumber && isValidEmail
    );
  }, [
    assignTo,
    paymentType,
    paymentOption,
    paymentPlans,
    isValidNumber,
    isValidEmail,
  ]);

  useEffect(() => {
    closeAlert();

    if (!isValidPlans) setIsValidPlans(true);
  }, [paymentType, paymentPlans]);

  // save phone number to object every time it changes
  useEffect(() => {
    setAssignTo({ ...assignTo, phoneNumber });
  }, [phoneNumber]);

  useEffect(() => {
    if (!isValidNumber) {
      setIsValidNumber(true);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (!isValidEmail) {
      setIsValidEmail(true);
    }
  }, [assignTo?.email]);

  useEffect(() => {
    // reset payment method everytime payment type (one-time/multiple) changes
    // reset back to online if not already
    if (paymentMethod !== "online") {
      setPaymentMethod("online");
    }
    // reset plans everytime payment type changes
    if (paymentPlans && Object.keys(paymentPlans)?.length > 0) {
      setPaymentPlans({});
    }
  }, [paymentType]);

  useEffect(() => {
    // reset payment option everytime payment method (cash/check) changes
    // reset back to cash if not already
    if (paymentOption !== "cash") {
      setPaymentOption("cash");
    }
  }, [paymentMethod]);

  const closeAlert = () => {
    setAlert({ show: false, variant: "", message: "" });
  };

  const validNumber = () => {
    return phoneNumber && isValidPhoneNumber(phoneNumber);
  };

  const handleValidNumber = (e) => {
    // check if valid
    setIsValidNumber(validNumber(phoneNumber));
  };

  const handleValid = (e) => {
    if (e.target.value) {
      setIsValidEmail(emailPatternMatch(e.target.value));
    }
  };

  const handleClose = () => {
    setShow(false);
    setAssignTo(initialState?.assignTo);
    setPaymentOption(initialState?.paymentOption);
    setPaymentType(initialState?.paymentType);
    setPaymentPlans(initialState?.paymentPlans);
    setPaymentMethod(initialState?.paymentMethod);
    setPhoneNumber();
    setIsValid(true);
  };

  const handleShow = () => {
    const userSelection = groupSelected();
    console.log("userSelection: ", userSelection);
    // If user has two distinct groups selected; dont open
    if (userSelection?.groupedSeatsToAssign && userSelection.sectionsToAssign) {
      setShowError(true);
      return;
    }

    // if user has two distinct groups selected in either group; dont open
    if (
      userSelection?.groupedSeatsToAssign?.length > 1 ||
      userSelection?.sectionsToAssign?.length > 1
    ) {
      console.log("two distinct groups selecteed");
      setShowError(true);
      return;
    }

    // Set whichever one has data and null the other
    if (userSelection?.sectionsToAssign?.length) {
      console.log("sectionsToAssing: ", userSelection.sectionsToAssign[0]);
      setSectionsToAssign(userSelection.sectionsToAssign[0]);
      setGroupedSeatsToAssign(null);
    } else if (userSelection?.groupedSeatsToAssign?.length) {
      setGroupedSeatsToAssign(userSelection.groupedSeatsToAssign[0]);
      setSectionsToAssign(null);
    }

    setShow(true);
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
    setEmail();
    navigate(`/mypackage/${eventPackage.uuid}/assign-packages`);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  const groupSelected = () => {
    const userSelection = {};
    const seats = selectedFanSeats?.seats;
    const GASections = selectedFanSeats?.gaSeats;

    if (seats) {
      const seatObjects = Object.keys(seats).map((seatId) => {
        const fullSeatObject = mapping.seats[seatId];
        return fullSeatObject;
      });
      // group by sectionNumber + rowNumber
      const groupedSeats = Object.values(
        seatObjects.reduce((acc, seat) => {
          const key = `${seat.sectionNumber}-${seat.rowNumber}`;
          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].push(seat);
          return acc;
        }, {})
      );

      console.log("groupedSeats: ", groupedSeats);
      userSelection.groupedSeatsToAssign = groupedSeats;
    }

    if (GASections) {
      const sectionObjects = Object.entries(GASections).map(([key, value]) => {
        const sectionObject = mapping.sections[key];
        return { ...sectionObject, selectedSeats: value };
      });

      console.log("sectionObject: ", sectionObjects);
      userSelection.sectionsToAssign = sectionObjects;
    }

    return userSelection;
  };

  const handleSave = () => {
    const selectedLength =
      sectionsToAssign !== null
        ? sectionsToAssign?.selectedSeats?.length
        : groupedSeatsToAssign?.length;
    let packageTotal =
      (parseFloat(eventPackage?.price) +
        parseFloat(
          eventPackage?.additionalTaxes ? eventPackage?.additionalTaxes : 0
        ) +
        parseFloat(
          eventPackage?.additionalFees ? eventPackage?.additionalFees : 0
        )) *
      selectedLength;

    const isValid = true;

    let data = {};
    let invoice = {};

    const isMultiple = paymentType === "multiple";

    data["packageUUID"] = eventPackage.uuid;

    invoice["assignTo"] = assignTo;
    invoice["paymentPlans"] = paymentPlans;
    invoice["paymentType"] = paymentType;
    invoice["paymentOption"] = isMultiple ? null : paymentOption;
    invoice["paymentMethod"] = isMultiple ? null : paymentMethod;

    invoice["selectedFanSeats"] = selectedFanSeats;
    // Includes full info about seats; rowNumber, sectionNumber etc
    invoice["groupedSeatsToAssign"] = groupedSeatsToAssign;
    invoice["sectionsToAssign"] = sectionsToAssign;

    data["invoice"] = invoice;

    // validation - payment amounts have to add up to total amount due
    if (paymentType === "multiple") {
      const total = Object.values(paymentPlans)?.reduce(
        (acc, cur) => acc + parseFloat(cur?.amount || 0),
        0
      );
      const doesAmountsMatchTotal = total == packageTotal;
      if (!doesAmountsMatchTotal) {
        setAlert({
          show: true,
          variant: "danger",
          message: `Amounts must add up to total amount due: ${formatCurrency(
            packageTotal
          )}.`,
        });
        setIsValid(false);
        setIsValidPlans(false);
        return;
      }
    }

    if (isValid) {
      setIsSaving(true);

      // emails can be from users not currently in the sytem so all is needed is to check if the format is correct
      createInvoice(data)
        .then((res) => {
          setIsSaving(false);
          if (!isSaving) {
            handleClose();
            setShowConfirm(true);
            setEmail(res?.data?.email);
          }
        })
        .catch((err) => {
          console.error(err);
          setIsSaving(false);
          setIsValid(false);
        });
    }
  };

  const handleChange = (e) => {
    if (e?.target)
      setAssignTo({ ...assignTo, [e.target.name]: e.target.value });
  };

  const handlePaymentType = (val) => {
    setPaymentType(val);
    setPaymentMethod("online");
  };

  const handleAddPlan = (e, total) => {
    let duration = Number(e.target.value);
    clearPlans(duration);
    let start = 1;
    let paymentsSplit = parseFloat(total / duration).toFixed(2);
    let installments = {};

    while (start <= duration) {
      let anniversaryDate = start - 1;
      installments[start] = {
        id: start,
        amount: paymentsSplit,
        date: new Date(
          moment("7:00 am", "h:mm a")
            .startOf("day")
            .add(anniversaryDate, "months")
            .format()
        ),
      };
      start++;
    }

    setPaymentPlans({
      ...paymentPlans,
      ...installments,
    });
  };
  // Clears existing plans if out of duration range
  const clearPlans = (duration) => {
    let paymentPlansCopy = paymentPlans;
    let keys = Object.keys(paymentPlans);

    for (let key of keys) {
      if (key > duration) delete paymentPlansCopy[key];
    }

    setPaymentPlans({
      ...paymentPlansCopy,
    });
  };

  const handlePaymentPlan = (e) => {
    if (e?.target) {
      const { id, name, value } = e.target;
      setPaymentPlans({
        ...paymentPlans,
        [id]: {
          ...paymentPlans[id],
          [name]: value,
        },
      });
    }
  };

  const setPaymentDate = (date, id) => {
    let paymentPlansCopy = paymentPlans;
    let keys = Object.keys(paymentPlans);

    for (let key of keys) {
      paymentPlansCopy[key].date = new Date(
        moment(date.date)
          .startOf("day")
          .add(key - 1, "months")
          .format()
      );
    }
    setPaymentPlans({ ...paymentPlansCopy });
  };

  return (
    <AssignToFanContext.Provider
      value={{
        show,
        handleClose,
        handleShow,
      }}
    >
      <AssignToFanModal
        show={show}
        alert={alert}
        packageStart={
          new Date(
            getFormattedTimezoneDate(
              eventPackage?.start,
              eventPackage?.timezone
            )
          )
        }
        assignTo={assignTo}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        handleValidNumber={handleValidNumber}
        isValidPhoneNumber={isValidNumber}
        paymentType={paymentType}
        handlePaymentType={handlePaymentType}
        paymentOption={paymentOption}
        setPaymentOption={setPaymentOption}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        handleChange={handleChange}
        paymentPlans={paymentPlans}
        handleAddPlan={handleAddPlan}
        handlePaymentPlan={handlePaymentPlan}
        setPaymentDate={setPaymentDate}
        isValid={isValid}
        handleValidEmail={handleValid}
        isValidEmail={isValidEmail}
        isValidPlans={isValidPlans}
        handleClose={handleClose}
        handleSave={handleSave}
        isSaving={isSaving}
        eventPackage={eventPackage}
        groupedSeatsToAssign={groupedSeatsToAssign}
        sectionsToAssign={sectionsToAssign}
      />
      <ConfirmationModal
        show={showConfirm}
        email={email}
        choice={paymentType}
        opt={paymentOption}
        handleClose={handleCloseConfirm}
      />

      <ErrorModal show={showError} handleClose={handleCloseError} />

      {children}
    </AssignToFanContext.Provider>
  );
};
