import React, { useEffect, useState } from 'react';
import moment from 'moment'

import { useBanner } from '../../providers/BannerProvider/BannerProvider';

import { updateEventStatus, updatePackageStatus } from '../../utilities/api';

import { formatDateTime, getFormattedTimezoneDate, getTimezoneDate, isISOString } from '../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import { BannerInfo } from '../BannerInfo';

import './banner.scss'

export default function Banner() {

    const { start, end, visibility, publishDate, setPublishDateLocal, isAfter, handlePublish, status, setStatus, setInitialState, setShow, setShowSchedule, isScheduled, setIsScheduled, obj, isEvent, isPublished, isSoldout, canPublish, hasEnded, isOnsale, update
    } = useBanner()

    const statuses = {
        1: {
            type: 'draft',
            label: 'Draft'
        },
        2: {
            type: 'scheduled',
            label: 'Scheduled'
        },
        3: {
            type: 'published',
            label: 'Published'
        },
        4: {
            type: 'onsale',
            label: 'On Sale'
        },
        5: {
            type: 'ended',
            label: 'Ended'
        },
        6: {
            type: 'soldout',
            label: 'Sold Out'
        },
        7: {
            type: 'cancel',
            label: 'Cancelled'
        }
    }

    const [showDropdownOpen, setShowDropdownOpen] = useState(false)

    useEffect(() => {
        // const date = publishDate

        // format publish date local to correct timezone only when date is in ISO format to diplay in date and time fields 
        setPublishDateLocal(publishDate ? (isISOString(publishDate) ? new Date(getFormattedTimezoneDate(publishDate, obj?.timezone)) : publishDate) : null)

        setIsScheduled(isAfter())
        // if obj has ended, status: ended

        // ended 
        if (hasEnded) {
            setStatus(5)

            // update status if obj is ended in context but not in db
            if (obj?.status) {
                if (obj?.status !== 'complete') {
                    updateStatus(obj, 'complete', isEvent)
                }
            }
        }

        // if obj is published; four statuses: published, onsale, cancelled, soldout

        // published
        else if (isPublished) {
            // TODO: hook up cancelled status - published but offer dates have not passed ?

            // sold out 
            if (isSoldout) {
                setStatus(6)
            }
            // onsale
            else if (isOnsale) {
                // update status if obj is onsale in context but not in db
                if (obj?.status) {
                    if (obj?.status !== 'on_sale') {
                        updateStatus(obj, 'on_sale', isEvent)
                    }
                }

                // on sale
                setStatus(4)
            } else {
                // published
                setStatus(3)

                // is published 
                if (obj?.status) {
                    // update status if obj is published in context but not in db
                    if (obj?.status !== 'published') {
                        updateStatus(obj, 'published', isEvent)
                    }
                }
            }
            // obj is not published

            // not published; three statuses: draft, scheduled, will be published  
        } else {
            // draft
            if (!publishDate) {
                setStatus(1)
            }

            // scheduled
            // publish date is set to future date
            // or create flow not complete 
            else if (isAfter() || !canPublish) {
                setStatus(2)
            }

            // published
            // is before today's date 
            else {
                // published 
                setStatus(3)
                // if publish date changes or if canPublish changes, if not already published, publish event 
                if (!isPublished) handlePublish(publishDate)
            }
        }

        setInitialState({
            publishDate,
            isScheduled: isAfter()
        })

    }, [publishDate, canPublish, isOnsale, isPublished, isSoldout, hasEnded, obj])

    const getTimezoneName = (format) => {
        const dateWithName = new Intl.DateTimeFormat("en-US", {
            timeZone: obj?.timezone?.utc[0],
            timeZoneName: format
        }).format(new Date())

        if (format === 'long') {
            return dateWithName?.split(" ")?.slice(1)?.join(" ")
        }
        else {
            return dateWithName?.split(" ")?.pop()
        }
    }

    // if event is onsale in context from newly created offer but event is not onsale; change its status 
    // if package is onsale in context but not in package object; change its status 
    const updateStatus = (obj, status, isEvent) => {
        let handler;
        if (isEvent) {
            handler = updateEventStatus;
        } else {
            handler = updatePackageStatus
        }
        const data = {}
        data['id'] = obj?.uuid;
        data["status"] = status
        handler({ data }).then(res => update(res?.data))
    }

    const handleShow = (action) => {
        setShowDropdownOpen(false)
        switch (action) {
            case 'publish':
                setShow(true)
                break;

            case 'schedule':
                setShowSchedule(true)

            default:
                break;
        }
    }

    return (
        <>
            <div className='banner banner--sticky' id="banner">
                <div className='banner-wrapper'>
                    <BannerInfo obj={obj} start={start} end={end} isEvent={isEvent} />
                    <Dropdown show={showDropdownOpen} id='status-dropdown' className="ms-auto" onToggle={() => setShowDropdownOpen(!showDropdownOpen)}>
                        <Dropdown.Toggle variant="default" id="status-dropdown"
                            className={`badge badge-lg badge--${statuses[status]?.type}`}
                        >
                            {statuses[status]?.label}
                        </Dropdown.Toggle>
                        {setShowDropdownOpen && (
                            <Dropdown.Menu className='w-xl dropdown-menu-lg'>
                                <span className='normal text-muted'>{isAfter() ? 'Scheduled on' : `${isEvent ? 'Event' : 'Package'} visibility`}:</span>
                                <h1 className='normal'>
                                    {visibility ? (
                                        <>
                                            <span>{isISOString(visibility) ? `${formatDateTime(getTimezoneDate(visibility, obj?.timezone), 'dateOnly')}` : `${formatDateTime(moment(visibility), 'dateOnly')}`}</span>
                                            <span className='time'>{isISOString(visibility) ? `${formatDateTime(getTimezoneDate(visibility, obj?.timezone), 'timeOnly')}` : `${formatDateTime(moment(visibility), 'timeOnly')}`}</span>
                                        </>
                                    ) : (
                                        "As soon as published"
                                    )}
                                </h1>
                                <span className="subtitle d-block mt-3">Timezone: {getTimezoneName('long')} ({getTimezoneName('short')})</span>
                                {/* not visible yet (published) and event has not ended */}
                                {(status !== 3 && status !== 6) && (
                                    <>
                                        {!canPublish && (
                                            <p className='mt-3 info-text info-text-md  info-text-danger'><span className="caption text-danger">{isEvent ? 'Event' : 'Package'} {visibility ? 'will' : 'cannot'} be published {visibility ? 'at scheduled time after' : 'without'} completing {isEvent ? 'event details, event setting, and seat map' : 'package details, and seat map'}</span></p>
                                        )}
                                        {/* event/package doesn't have visibility or is after today's date (draft or scheduled at later date) */}
                                        {(!visibility || isScheduled || !canPublish) && (
                                            <Stack direction="horizontal" className='btn-group-flex btn-group-flex-justify btn-group-flex-sm seperator seperator-sm'>
                                                {isEvent && (
                                                    <Button
                                                        variant="outline-light"
                                                        onClick={() => handleShow('schedule')}
                                                    >
                                                        {visibility ? 'Reschedule' : 'Schedule time'}
                                                    </Button>
                                                )}
                                                <Button
                                                    disabled={!canPublish}
                                                    onClick={() => handleShow('publish')}
                                                >Publish now</Button>
                                            </Stack>
                                        )}
                                    </>
                                )}
                            </Dropdown.Menu>
                        )}
                    </Dropdown>
                </div>
            </div>
        </>
    );
}
