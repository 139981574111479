import React, { useEffect } from "react";

import { useFromPage } from "../../../providers/FromPageProvider";
import { useAssignToFanModal } from "../AssignToFanProvider";

import Button from "react-bootstrap/Button";

import { SeatMapNavigation } from "../../SeatMapNavigation";
import { Spinner } from "../../LoadingContainer/Spinner";

export default function Navigation({
    eventPackage,
    isPackagePublished,
    hasPackageEnded,
    isMapDisabled,
    configuration,
    selectedSeatIds,
    handleShowResetConfiguration,
    saveSeatSelections,
    handleGoBack,
    isDisabled,
    isSaving
}) {

    const { isAssigningToFan } = useFromPage()

    const { handleShow } = useAssignToFanModal()

    useEffect(() => {
    }, [selectedSeatIds])

    const getButton = () => {
        return isAssigningToFan ? (
            <Button onClick={handleShow} disabled={(isMapDisabled || !selectedSeatIds || Object.keys(selectedSeatIds)?.length === 0)}>Assign To Fan</Button>
        ) : (
            <Button onClick={() => saveSeatSelections()} className="btn-width btn-width-md--flex-item" disabled={isDisabled || isMapDisabled || (!selectedSeatIds || Object.keys(selectedSeatIds)?.length === 0) || isSaving}>{
                isSaving ? (
                    <Spinner />
                ) :
                    'Save & Continue'
            }
            </Button>
        );
    };

    return (
        <SeatMapNavigation isEvent={false} eventPackage={eventPackage} configuration={configuration} showReset={!isAssigningToFan} isDisabled={(isPackagePublished || hasPackageEnded)} handleLeave={handleGoBack} handleShowReset={handleShowResetConfiguration} getButton={getButton} />
    );
}
