import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { formatCurrency, copy, getSinglarOrPluralWord, formatDateTime, getTimezoneDate, formatNumber, getPreviewImage, capitalizeString } from '../../../utilities/helpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { InfoIcon } from '../../InfoIcon';

import placeholder from '../../../assets/placeholder.png'

import './cards.scss';

export default function Cards({ eventPackage, stats, isVisible, handleChange, isDisabled }) {

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 2000)
        }
    }, [copied])

    return (
        <section className='cards'>
            <Row>
                <Col xs={8}>
                    <Stack gap={4}>
                        <Card body className='card--light'>
                            <div className='card-body-heading--sm'>
                                <Card.Title as="h5" className='card-title-upper'>Overview</Card.Title>
                            </div>
                            <Stack direction='horizontal' gap={5} id="overview-box" className='justify-space-between align-items-start relative'>
                                <div className='flex-grow-1'>
                                    <div className='card-body-heading heading--flex total-sales'>
                                        <Stack>
                                            <Stack direction="horizontal" className="small-label--flex">
                                                <span className='small-label'>Sales revenue</span>
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={<Tooltip>Revenue include net sales.</Tooltip>}>
                                                    <Button variant="link">
                                                        <InfoIcon variant="dark" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Stack>
                                            <h5 className='card-title card-title-lg card-body-title card-body-title-lg'>{formatCurrency(stats?.totalSales)}</h5>
                                        </Stack>
                                    </div>
                                    <Stack direction='horizontal' gap={1}>
                                        <span className='small small-bold'>Package price</span>
                                        <span className='fw-semibold'>{formatCurrency(eventPackage?.price)}</span>
                                    </Stack>
                                </div>
                                <div className="flex-grow-1 ps-4">
                                    <div className='card-body-heading heading--flex sold'>
                                        <Stack>
                                            <Stack direction="horizontal" className="small-label--flex">
                                                <span className='small-label'>Packages sold</span>
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={<Tooltip>Number of packages sold.</Tooltip>}>
                                                    <Button variant="link">
                                                        <InfoIcon variant="dark" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Stack>
                                            <h5 className='card-title card-title-lg card-body-title card-body-title-lg'>{formatNumber(stats?.packagesSold)}</h5>
                                        </Stack>
                                    </div>
                                </div>
                            </Stack>
                            <div className="seperator">
                                <Stack direction='horizontal' gap={4}>
                                    <div>
                                        <div className="card-body-heading--xs">
                                            <Card.Title as="h5" className='card-title-upper'>Seat Map</Card.Title>
                                            <Card.Subtitle as="h6" className='mt-2'>{capitalizeString(eventPackage?.seatmap?.name)}</Card.Subtitle>
                                        </div>
                                        <Link to='seatmap' className="btn btn-primary">Open Seat Map</Link>
                                    </div>
                                    <Image src={getPreviewImage(eventPackage?.venue?.slug) || placeholder} width={100} height={200} className='ms-auto' alt={`Picture of ${capitalizeString(eventPackage?.seatmap?.name)} Map`} />
                                </Stack>
                            </div>
                        </Card>
                    </Stack>
                </Col>
                <Col xs={4} className='d-flex'>
                    <Stack gap={4}>
                        <Card body className='card--md card--light'>
                            <div className='card-body-heading--sm'>
                                <Card.Title as="h5" className='card-title-upper'>Package Details</Card.Title>
                            </div>
                            <Card.Img src={eventPackage?.image?.url || placeholder} width="332" height="145" alt={`Cover art for ${eventPackage?.name} package`} className='event-image package-img mb-3' />
                            <h4 className='fs-md fs-md-bold'>{eventPackage?.name}</h4>
                            <span className='normal normal-bold'>{formatNumber(eventPackage?.events?.length)} {getSinglarOrPluralWord(eventPackage?.events?.length, 'Event')}</span>
                            <span className='subtitle fw-medium'>
                                <span>{formatDateTime(getTimezoneDate(eventPackage?.start, eventPackage?.timezone), 'dateOnly')}</span>
                                <span className='to'>{formatDateTime(getTimezoneDate(eventPackage?.end, eventPackage?.timezone), "dateOnly")}</span>
                            </span>
                            <Link to='package-details' className='small small-bold link-goTo mt-3'>Manage package details</Link>
                            <Card body id="visibility-card" className='card--sm card-with-border h-auto mt-4'>
                                <Form.Check
                                    type="switch"
                                    id="package-visibility"
                                    label="Package Visibility"
                                    checked={isVisible}
                                    onChange={(e) => { handleChange(e, e.target.checked) }}
                                    disabled={isDisabled}
                                />
                                <small className='subtitle subtitle--dark fw-medium'>
                                    Package will be visible to attendees
                                </small>
                            </Card>
                        </Card>
                        <Card className='card--md'>
                            <Card.Body className='d-flex-column'>
                                <div className='card-body-heading--xs'>
                                    <Card.Title as="h5" className='card-title-upper'>Package Listing</Card.Title>
                                    <Card.Subtitle as="h6" className='subtitle--dark mt-2 fw-medium'>Your package listing is now available</Card.Subtitle>
                                </div>
                                <Stack direction='horizontal' className='mt-auto btn-group-flex btn-group-flex-justify btn-group-flex-sm'>
                                    <Button variant="outline-light" className={`btn-width ${copied ? 'btn-copy--success' : ''} `} onClick={() => copy(`${process.env.REACT_APP_CONSUMER_PORTAL}/venue/${eventPackage?.venue?.slug}/package/${eventPackage?.uuid}`, setCopied, true)} disabled={isDisabled}>
                                        {!copied && 'Copy URL'}
                                    </Button>
                                    <Link to={`${process.env.REACT_APP_CONSUMER_PORTAL}/venue/${eventPackage?.venue?.slug}/package/${eventPackage?.uuid}`} target="_blank" className={`btn btn-primary btn-lg ${isDisabled ? 'btn-link-disabled' : ''}`}>Open event page</Link>
                                </Stack>
                            </Card.Body>
                        </Card>
                    </Stack>
                </Col>
            </Row>
        </section>
    );
}
