import React from "react";

import {
    formatCurrency,
    getSinglarOrPluralWord,
    namePatternMatch,
    capitalizeString
} from "../../../../utilities/helpers";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { PhoneNumberInput } from "../../../PhoneNumberInput";
import { Error } from "../../../Error";
import { Plan } from "./Plan";
import { Spinner } from "../../../LoadingContainer/Spinner";

export default function AssignToFanModal({
    show,
    alert,
    packageStart,
    assignTo,
    phoneNumber,
    setPhoneNumber,
    handleValidNumber,
    isValidPhoneNumber,
    paymentType,
    handlePaymentType,
    paymentOption,
    setPaymentOption,
    paymentMethod,
    setPaymentMethod,
    isSameAsInitial,
    handleChange,
    paymentPlans,
    handleAddPlan,
    handlePaymentPlan,
    setPaymentDate,
    isValid,
    handleValidEmail,
    isValidEmail,
    isValidPlans,
    handleClose,
    isSaving,
    handleSave,
    eventPackage,
    sectionsToAssign,
    groupedSeatsToAssign,
}) {
    const total = () => {
        const pricePerSelected =
            parseFloat(eventPackage?.price) +
            parseFloat(
                eventPackage?.additionalTaxes ? eventPackage?.additionalTaxes : 0
            ) +
            parseFloat(
                eventPackage?.additionalFees ? eventPackage?.additionalFees : 0
            );
        const selectedLength = sectionsToAssign !== null ? sectionsToAssign?.selectedSeats?.length : groupedSeatsToAssign?.length;
        return selectedLength * pricePerSelected;
    };

    const getSeats = (seatObjects) => {
        if (seatObjects.length === 0) return "";

        // Parse numbers once during mapping
        const seatNumbers = seatObjects
            .map((seat) => parseInt(seat.seatNumber))
            .sort((a, b) => a - b);

        const ranges = [];
        let start = seatNumbers[0];
        let prev = seatNumbers[0];

        for (let i = 1; i <= seatNumbers.length; i++) {
            const current = seatNumbers[i];
            if (i === seatNumbers.length || current !== prev + 1) {
                ranges.push(start === prev ? start.toString() : `${start}-${prev}`);
                start = current;
            }
            prev = current;
        }

        // If there's only one range and it's a consecutive range (contains a hyphen)
        if (ranges.length === 1 && ranges[0].includes("-")) {
            return `Seats: ${ranges[0]}`;
        }

        return `${getSinglarOrPluralWord(
            seatNumbers.length,
            "Seat"
        )}: ${ranges.join(", ")}`;
    };

    return (
        <Modal
            centered
            className='modal-xl'
            animation={false}
            fullscreen='md-down'
            show={show}
            onHide={handleClose}
            backdrop='static'
            contentClassName='h-lg'
        >
            <Modal.Body className='d-flex-column'>
                <div className='modal-body-heading'>
                    <h1 className='modal-body-heading-title'>Assign package to fan</h1>
                    <small className='subtitle subtitle--dark'>
                        The fan will receive an email with instructions to accept the
                        assigned package.
                    </small>
                </div>
                <Stack gap={4}>
                    <Row className='m-0'>
                        <Col>
                            <Form.Group className='form-group' controlId='firstName'>
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='firstName'
                                    pattern={namePatternMatch}
                                    placeholder='Enter your first name'
                                    value={assignTo?.firstName || ""}
                                    onChange={(e) =>
                                        handleChange(
                                            e.target.value === "" || e.target.validity.valid
                                                ? e
                                                : assignTo?.firstName
                                        )
                                    }
                                    required
                                    autoFocus
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='form-group' controlId='lastName'>
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='lastName'
                                    pattern={namePatternMatch}
                                    placeholder='Enter your last name'
                                    value={assignTo?.lastName || ""}
                                    onChange={(e) =>
                                        handleChange(
                                            e.target.value === "" || e.target.validity.valid
                                                ? e
                                                : assignTo?.lastName
                                        )
                                    }
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='m-0'>
                        <Col>
                            <Form.Group className='form-group' controlId='email'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type='email'
                                    name='email'
                                    placeholder='Enter your email address'
                                    value={assignTo?.email || ""}
                                    onChange={handleChange}
                                    onBlur={handleValidEmail}
                                    required
                                    className={`${!isValidEmail ? "error-border" : ""}`}
                                />

                                {!isValidEmail && <Error type='invalidEmail' />}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='form-group' controlId='lastName'>
                                <Form.Label>Phone number</Form.Label>
                                <PhoneNumberInput
                                    phoneNumber={phoneNumber}
                                    setPhoneNumber={setPhoneNumber}
                                    onBlur={handleValidNumber}
                                    hasError={!isValidPhoneNumber}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {(sectionsToAssign !== null || groupedSeatsToAssign !== null) && (
                        <div className='list-item list-item--with-seperator list-item-lg list-item-lg--sm list-item--primary gap-4'>
                            <Stack>
                                <span className='text-muted normal'>Assigned to:</span>
                                <span className='normal normal-bold'>
                                    {sectionsToAssign !== null ? (
                                        <>
                                            <span>Sec {capitalizeString(sectionsToAssign.sectionNumber)}</span>
                                            <span>
                                                {" "}
                                                - {sectionsToAssign.selectedSeats.length} {getSinglarOrPluralWord(sectionsToAssign.selectedSeats.length, 'Seat')}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span>Sec {groupedSeatsToAssign[0].sectionNumber}</span>
                                            <span className='loc'>
                                                Row {groupedSeatsToAssign[0].rowNumber}
                                            </span>
                                            <span className='loc'>
                                                {getSeats(groupedSeatsToAssign)}
                                            </span>
                                        </>
                                    )}
                                </span>
                            </Stack>
                            <Stack className='ps-4'>
                                <span className='text-muted normal'>Amount to pay:</span>
                                <span className='normal normal-bold'>
                                    {formatCurrency(total())} USD
                                </span>
                            </Stack>
                        </div>
                    )}
                    <div>
                        <div className='mb-3'>
                            <h4 className='normal normal-bold'>Payment Options</h4>
                            <small>Select a payment plan that applies</small>
                        </div>
                        <Stack direction='horizontal' gap={4}>
                            <Form.Check
                                type='radio'
                                name='payment-opt'
                                checked={paymentType === "one-time"}
                                id='one-time'
                                label='One time payment'
                                onChange={() => handlePaymentType("one-time")}
                            />
                            <Form.Check
                                type='radio'
                                name='payment-opt'
                                id='multiple'
                                label='Multiple payments'
                                checked={paymentType === "multiple"}
                                onChange={() => handlePaymentType("multiple")}
                            />
                        </Stack>
                        {paymentType === "one-time" ? (
                            <>
                                <h5 id='accepting payment' className='mb-3 small small-bold'>
                                    Accepting payment
                                </h5>
                                <Form.Select
                                    name='accepting-payment'
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                    value={paymentMethod}
                                    required
                                    aria-labelledby='accepting-payment'
                                >
                                    <option disabled hidden value=''>
                                        Select payment form
                                    </option>
                                    <option value='online'>Online payment</option>
                                    <option value='in-person'>In-person payment</option>
                                </Form.Select>
                                <>
                                    {paymentMethod === "online" ? (
                                        <Alert className='ps-0' variant='default'>
                                            A Stripe payment link will be sent to the email address
                                            provided above
                                        </Alert>
                                    ) : (
                                        <>
                                            {paymentMethod === "in-person" && (
                                                <div className='mt-3'>
                                                    <h5 className='mb-3 small small-bold'>Paid by</h5>
                                                    <Form.Select
                                                        name='paidBy'
                                                        onChange={(e) => setPaymentOption(e.target.value)}
                                                        value={paymentOption}
                                                        required
                                                        aria-label='Payment method'
                                                    >
                                                        <option disabled hidden value=''>
                                                            Payment method
                                                        </option>
                                                        <option value='cash'>Cash</option>
                                                        <option value='check'>Check</option>
                                                    </Form.Select>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            </>
                        ) : (
                            <>
                                {paymentType === "multiple" && (
                                    <>
                                        {alert.show && (
                                            <>
                                                <Alert variant={alert.variant} className='mb-5'>
                                                    <p>{alert.message}</p>
                                                </Alert>
                                            </>
                                        )}
                                        <h5 className='small small-bold mb-3'>Payment plans</h5>
                                        <Stack gap={3}>
                                            <Form.Select
                                                aria-label='Select Plan duration'
                                                onChange={(e) => handleAddPlan(e, total())}
                                            >
                                                <option>Select Plan Duration</option>
                                                <option value='2'>Two Month Plan</option>
                                                <option value='3'>Three Month Plan</option>
                                                <option value='4'>Four Month Plan</option>
                                                <option value='5'>Five Month Plan</option>
                                                <option value='6'>Six Month Plan</option>
                                            </Form.Select>
                                            <Stack as='ul' gap={3}>
                                                {Object.values(paymentPlans)?.map((plan, idx) => (
                                                    <Plan
                                                        key={idx}
                                                        plan={plan}
                                                        idx={idx}
                                                        packageStart={packageStart}
                                                        hasError={!isValidPlans}
                                                        handlePaymentPlan={handlePaymentPlan}
                                                        setPaymentDate={setPaymentDate}
                                                    />
                                                ))}
                                            </Stack>
                                        </Stack>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </Stack>
                <Stack direction='horizontal' className=' btn-group-flex'>
                    <Button variant='default' size='lg' onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        size='lg'
                        className='btn-width btn-width-xs'
                        disabled={isSameAsInitial || !isValid || isSaving}
                        onClick={handleSave}
                    >
                        {isSaving ? <Spinner /> : "Save"}
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
