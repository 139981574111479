import React, { useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';

import LoadingContext from '../../context/Loading/Loading';
import UserContext from '../../context/User/User';
import EventDetailsContext from '../../context/EventDetails/EventDetails';

import AuthService from '../../utilities/services/auth.service';

import { getAttendeesList, checkIn } from '../../utilities/api';
import { capitalizeString, checkPermission, formatNumber } from '../../utilities/helpers';

import ProgressBar from "react-bootstrap/ProgressBar";
import Card from "react-bootstrap/Card";
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { EmptyContainer } from '../EmptyContainer';
import { AttendeeList } from './AttendeeList';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { Spinner } from '../LoadingContainer/Spinner';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function AttendeesWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { event, hasEventEnded } = useContext(EventDetailsContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [attendees, setAttendees] = useState([])

    const [selectedAttendee, setSelectedAttendee] = useState()

    const [show, setShow] = useState(false)

    const [percentage, setPercentage] = useState(0)

    const [totals, setTotals] = useState({
        checkedIn: 0,
        total: 0
    })

    const [isConfirming, setIsConfirming] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 11));

    }, [orgPermissions])

    useEffect(() => {
        showLoading()
        getEventAttendees()

        const socket = io(process.env.REACT_APP_AUTH);

        socket.on("connect", () => {
            socket.on(`attendeeList:${eventId}`, (data) => {
                getEventAttendees()
            })
        });
    }, [])

    useEffect(() => {
        setPercentage(totals.checkedIn / totals.total * 100)
    }, [totals])

    const getEventAttendees = () => {
        getAttendeesList(eventId)
            .then((res) => {
                setAttendees(res?.data?.attendees)
                setTotals({
                    checkedIn: res.data?.attendees?.filter(a => a.checkedIn === true)?.length,
                    total: res.data?.attendees?.length
                })
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    const checkInAttendee = (attendee) => {
        setSelectedAttendee(attendee)
        setShow(true)
    }

    const confirmed = () => {
        setIsConfirming(true)
        let data = {
            uuid: eventId,
            checkInCode: selectedAttendee?.ticket ? selectedAttendee?.ticket?.checkInCode : selectedAttendee?.guest_pass?.checkInCode
        }
        checkIn(data)
            .then((res) => {
                getEventAttendees()
                setIsConfirming(false)

                if (!isConfirming) {
                    setShow(false)
                }
            })
            .catch((err) => {
                console.error(err)
                setIsConfirming(false)
            })
    }

    const handleClose = () => setShow(false);

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <div className="position-relative">
                        <section className={`max-width-wrapper ${!hasPermission ? 'overlay' : ''}`}>
                            <header className='section-header'>
                                <div className="section-header" >
                                    <div className="section-heading">
                                        <h1>Attendees</h1>
                                    </div>
                                    <p className='section-header-desc'>Check in attendees by scanning the QR code on their smart ticket</p>
                                </div>

                            </header>
                            <section>
                                {attendees && attendees?.length > 0 ? (
                                    <>
                                        <p className='fw-medium mb-5'>Check in attendees with your mobile device: <span className='text-primary'>iPhone</span></p>
                                        <Card className='section-header'>
                                            <Card.Body className='pt-3 pb-4'>
                                                <div className='card-body-heading--flex-space-between mb-3'>
                                                    <h4 className='normal'>Attendees checked in</h4>
                                                    <span className='text-muted fw-medium'><span className='text-dark fs-md fw-bold'>{formatNumber(totals.checkedIn)}</span> / {formatNumber(totals.total)}</span>
                                                </div>
                                                <ProgressBar now={percentage} />
                                            </Card.Body>
                                        </Card>
                                        <section>
                                            <AttendeeList eventTimezone={event?.timezone} attendees={attendees} eventId={eventId} checkInAttendee={checkInAttendee} hasEventEnded={hasEventEnded} />
                                        </section>
                                    </>
                                ) : (
                                    <Card body>
                                        <EmptyContainer style="center lg">
                                            <p>There are no attendees registered for this event.</p>
                                        </EmptyContainer>
                                    </Card>
                                )}
                            </section>
                        </section>
                        {!hasPermission && (
                            <NoPermissionsContainer />
                        )}
                    </div>

                    <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static" className='modal--lg'>
                        <Modal.Body>
                            <div className="modal-body-heading">
                                <h1 className="modal-body-heading-title">Are you sure you want to check in {capitalizeString(selectedAttendee?.users_permissions_user?.firstName)} {capitalizeString(selectedAttendee?.users_permissions_user?.lastName)}?</h1>
                            </div>
                            <Stack className="btn-group-flex">
                                <Button variant="outline-light" size="lg" onClick={confirmed}>
                                    {isConfirming ? (
                                        <Spinner variant="dark" />
                                    ) : (
                                        "Check in"
                                    )}
                                </Button>
                                <Button size="lg" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </>
    );
}
